import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '48, 36, 94',
		'primary-dark': '28, 21, 55',
		'accent': '225, 65, 38',
		'accent-plus': '255, 255, 255',
	},
});
